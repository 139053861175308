<template>
  <div>
    <v-dialog
        v-model="ticketModal"
        max-width="765"
        @input="$emit('close')"
    >
      <v-card class="pa-0">
        <div class="pa-4">
          <div class="border-bottom d-flex justify-space-between align-center">
            <h3 class="font-semibold text-blue pb-2">
              Select Tickets
            </h3>
            <div class="pointer" @click="$emit('close')">
              <ModalCloseIcon />
            </div>
          </div>
          <div v-for="(ticket,index) in localTickets" :key="ticket.id"
               class="d-flex justify-space-between align-center px-2 py-4 bordered rounded my-4">
            <p class="mb-0 font-medium">
              {{ ticket.name }}
            </p>
            <p class="mb-0 font-semibold">
              {{ ticket.price + ticket.tax  | toCurrency }}
            </p>
            <div class="d-flex align-center gap-x-2 quantity">
              <div>
                <v-btn text x-small @click="reduceQuantity(index)">
                  <MinusIcon/>
                </v-btn>
              </div>
              <div class="font-medium">
                {{ ticket.quantity || 0 }}
              </div>
              <div>
                <v-btn text x-small @click="addQuantity(index)">
                  <PlusIcon/>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 d-flex justify-space-between align-center bg-cyan pa-4">
          <p class="font-semibold mb-0">Total: {{ getPrice() | toCurrency }}</p>
          <v-btn
              class="white--text blue-color"
              height="40"
              text
              @click="addToCart"
          >
            Add to Cart
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <ShopCustomerPopup
        v-if="showCustomerPopup"
        bookingType="event"
        :promotions="promotions"
        :show-customer-popup="showCustomerPopup"
        @close="closeCustomerPopup"
        @setCustomers="setCustomers"
        :max-customers="getMaxCustomers()"
        :bookingData="bookingForm"
    />
  </div>
</template>

<script>
import MinusIcon from "@/assets/images/events/minus-icon.svg";
import PlusIcon from "@/assets/images/events/plus-icon.svg";
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import moment from "moment/moment";
export default {
  name: "EventTickets",
  components: {
    ShopCustomerPopup,
    MinusIcon,
    PlusIcon,
    ModalCloseIcon,
  },
  props: {
    eventTicketModal: {type: Boolean, required: true},
    event: {
      type: Object, default: () => {
      }
    },
    venueServiceId: {type: Number, default: null},
    tickets: {type: Array, required: true},
  },
  watch: {
    eventTicketModal(val) {
      if (val) {
        if (this.tickets.length > 0) {
          this.localTickets = [...this.tickets];
          this.localTickets.map(ticket => {
            ticket.event_ticket_id = ticket.id;
            ticket.tax_amount = ticket.tax;
          })
          this.bookingForm.event_id = this.event.id;
          this.bookingForm.event_name = this.event.name;
          this.bookingForm.venue_service_id = this.event.venue_service_id;
          this.bookingForm.start_date = this.event.start_date;
        }
      } else {
        this.resetBookingForm();
      }
    }
  },
  computed: {
    getCurrentSVID() {
      return this.$store.getters.svid ? this.$store.getters.svid : this.$store.getters.userInfo.venue_id;
    },
    ticketModal: {
      get() {
        return this.eventTicketModal
      },
      set(value) {
        this.$emit('close', value)
      }
    },
    promotions() {
      return this.$store.getters.getShopPromotions.data;
    },
  },
  mounted() {
    if (this.tickets.length > 0) {
      this.localTickets = [...this.tickets];
      this.localTickets.map(ticket => {
        ticket.event_ticket_id = ticket.id;
        ticket.tax_amount = ticket.tax;
      })
      this.bookingForm.event_id = this.event.id;
      this.bookingForm.event_name = this.event.name;
      this.bookingForm.venue_service_id = this.event.venue_service_id;
      this.bookingForm.start_date = this.event.start_date;

    }
  },
  data() {
    return {
      showCustomerPopup: false,
      localTickets: [],
      bookingForm: {
        start_date: moment().format("YYYY-MM-DD"),
        products: [],
        productCategoryId: null,
        attendance_count: 1,
        opt_marketing: false,
        price: 0,
        discount: null,
        promotion_code: null,
        total_price: 0,
        venue_service_id: null,
        event_id: null,
        event_name: null,
      }
    }
  },
  methods: {
    async setCustomers(data) {

      let cartData = {products: []};
      cartData.venueId = this.getCurrentSVID;
      cartData.type = "event";
      cartData.event_id = this.bookingForm.event_id;
      cartData.event_name = this.bookingForm.event_name;
      cartData.venue_service_id = this.bookingForm.venue_service_id;
      cartData.start_date = this.bookingForm.start_date;
      cartData.date = this.bookingForm.start_date;
      cartData.discount = this.bookingForm.discount ? this.bookingForm.discount : null;
      if (this.bookingForm.promotion_code) {
        cartData.promotion_code = this.bookingForm.promotion_code;
      }

      data.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      // cartData.customers = data;

      let tickets = this.bookingForm.products;
      tickets = tickets.map((ele) => {
        if (ele.quantity > 0) {
          if (ele.discount) {
            if (ele.product_price && ele.price >= ele.product_price) {
              ele.price = (ele.price / ele.quantity);
              ele.tax = (ele.tax / ele.quantity);
            }
            ele.total_price = ele.price + ( (ele.tax || ele.tax === 0) ? ele.tax : ele.tax_amount);
          } else {
            if (ele.product_price && ele.price > ele.product_price) {
              ele.price = (ele.price / ele.quantity);
              if ( (ele.tax || ele.tax === 0) && (ele.tax_amount || ele.tax_amount === 0)  && ele.tax > ele.tax_amount) {
                ele.tax = (ele.tax / ele.quantity);
              }
              ele.total_price = ele.price + ((ele.tax_amount || ele.tax_amount === 0)? ele.tax_amount : ele.tax);
              // ele.total_price = ele.price + (ele.tax?ele.tax:ele.tax_amount);
            } else {
              ele.total_price = ele.price + ((ele.tax || ele.tax === 0) ? ele.tax : ele.tax_amount);
            }
          }
        } else {
          ele.total_price = 0;
        }
        return ele;
      });
      let pushObject = tickets.filter(ele => ele.quantity > 0)

      cartData.products = [...cartData.products, ...pushObject];
      let newCartData = JSON.parse(JSON.stringify(cartData));
      newCartData.customers = [...data];
      this.$store.dispatch("addToCart", newCartData).then( () => {
        this.resetBookingForm();
        this.showCustomerPopup = false;
        this.$emit('checkProductOffers');
      });
    },
    addToCart() {
      this.bookingForm.products = [];
      this.localTickets.forEach(ticket => {
        if (ticket.quantity > 0) {
          this.bookingForm.products.push({...ticket});
        }
      });
      if (this.bookingForm.products.length) {
        this.showCustomerPopup = true;
      } else {
        this.showError('Please add a ticket');
      }
    },
    closeCustomerPopup() {
      this.showCustomerPopup = false;
    },
    addQuantity(index) {
      this.localTickets[index].quantity++;
    },
    reduceQuantity(index) {
      if (this.localTickets[index].quantity > 0)
        this.localTickets[index].quantity--;
    },
    getMaxCustomers() {
      return this.localTickets.reduce((sum, item) => {
        return sum + (item.quantity * item.participant_count);
      }, 0);
    },
    getPrice() {
      return this.localTickets.reduce((sum, item) => {
        return sum + (item.quantity * item.price) + (item.quantity * item.tax);
      }, 0);
    },
    resetBookingForm() {
      this.bookingForm = {
        start_date: moment().format("YYYY-MM-DD"),
        products: [],
        productCategoryId: null,
        attendance_count: 1,
        opt_marketing: false,
        price: 0,
        discount: null,
        total_price: 0,
        venue_service_id: null,
        event_id: null,
        event_name: null,
      }
    },
  }
}
</script>

<style scoped>
.quantity > div {
  height: 21px;
  cursor: pointer;
}
</style>

