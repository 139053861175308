<template>
  <div class="shadow rounded-lg p-4">
    <div class="pb-2 mb-2 border-bottom text-center">
      <p class="font-semibold text-lg line-clamp-1 mb-0">{{ event.name }}</p>
    </div>
    <v-row>
      <v-col class="px-3" cols="5">
        <v-img :aspect-ratio="1" :src="event.image_path
                            ? s3BucketURL + event.image_path
                            : require('@/assets/images/default_images/event_default.jpg')" class="rounded-2"></v-img>
      </v-col>
      <v-col class="px-0" cols="7">
        <div class="d-flex align-center">
          <CalendarIcon class="mr-2"/>
          <p class="text-xs mb-0">{{ event.date }}</p>
        </div>
        <div class="d-flex align-center mt-2">
          <ClockIcon class="mr-2"/>
          <p class="text-sm mb-0">{{ event.venue_service }}</p>
        </div>
        <v-btn
            class="white--text teal-color mt-4" height="36"
            text
            @click="eventTicketModalOpen"
        >
          See Tickets
        </v-btn>
      </v-col>
    </v-row>
    <EventTickets
        :event-ticket-modal="eventTicketModal"
        :tickets="event.tickets"
        :event="event"
        @checkProductOffers="checkProductOffers"
        @close="closeModal"
    />
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="closeOfferPopup"/>
  </div>
</template>

<script>
import CalendarIcon from "@/assets/images/events/calendar-icon.svg";
import ClockIcon from "@/assets/images/events/clock-icon.svg";
import EventTickets from "@/components/Shop/Events/EventTickets.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";
import moment from "moment";
export default {
  name: "EventCard",
  components: {EventTickets, CalendarIcon, ClockIcon,PackageCardPopup},
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      eventTicketModal: false,
      date: null,
      offers: [],
      showOfferPopup: false,
    }
  },
  computed:{
    getCurrentVenueId(){
      return this.$store.getters.svid;
    },
  },  
  methods:{
    closeModal(){
      this.eventTicketModal = false;
      this.event.tickets.forEach((ele) => {
        ele.quantity = 0;
      })
    },
    eventTicketModalOpen(){
      this.date = moment(this.event.start_date, "YYYY-MM-DD").isAfter()? moment(this.event.start_date, "YYYY-MM-DD").format("YYYY-MM-DD"): moment().format("YYYY-MM-DD");
      this.$store.dispatch("loadShopPromotions", {
        date: this.date,
        product_type: "Event",
        venueId: this.getCurrentVenueId,
      }).then( () => {
        this.eventTicketModal = true;
      });
    },
    closeOfferPopup(){
      this.showOfferPopup = false;
      this.offers = [];
      this.$emit('close');
    },
    checkProductOffers(){
      this.closeModal();
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
        if (response && response.status === 200 && response.data.status === true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.offers = [];
          }
        }
      });
    },
  }
}
</script>

<style scoped>

</style>